import { render, staticRenderFns } from "./MailroomReceipt.vue?vue&type=template&id=de9d9290&scoped=true"
import script from "./MailroomReceipt.vue?vue&type=script&lang=js"
export * from "./MailroomReceipt.vue?vue&type=script&lang=js"
import style0 from "./MailroomReceipt.vue?vue&type=style&index=0&id=de9d9290&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de9d9290",
  null
  
)

export default component.exports